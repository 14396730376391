import React, { Component } from 'react';
import FsLightbox from 'fslightbox-react';
import Masonry from 'react-masonry-css';

import './GalleryLightbox.sass';

class GalleryLightbox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            toggler: false,
            slide: 1
        };
        this.toggleLightbox = this.toggleLightbox.bind(this);
    }

    toggleLightbox(slide) {
        this.setState({
            toggler: !this.state.toggler,
            slide: slide
        })
    }

    render() {
        const { imgArr } = this.props;

        const images = imgArr.map((item, i) => (
            <div>
                <img src={item} className="w-100" alt="" onClick={() => this.toggleLightbox(i + 1)} />
            </div>
        ));

        const breakpointColumnsObj = {
            default: 5,
            1100: 4,
            700: 3,
            500: 2
        };

        return (
            <>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {images}
                </Masonry>

                <FsLightbox
                    toggler={this.state.toggler}
                    sources={imgArr}
                    slide={this.state.slide}
                />
            </>
        );
    }
}
export default GalleryLightbox;