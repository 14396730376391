import React from 'react'
import { injectIntl, FormattedHTMLMessage } from 'react-intl'
import { compose } from 'recompose'
import GalleryLightbox from '../components/GalleryLightbox'
import SEO from '../components/seo'
import withPageContext from '../pageContext'
import withLayout from '../layout'

export const query = graphql`
    query {
        zalisky: allFile(
            filter: { absolutePath: { regex: "/reference/zalisky/" } }
        ) {
            edges {
                node {
                    publicURL
                }
            }
        }
        nytovaci_matice: allFile(
            filter: { absolutePath: { regex: "/reference/nytovaci_matice/" } }
        ) {
            edges {
                node {
                    publicURL
                }
            }
        }
    }
`

class ReferencePage extends React.Component {
    // const ReferencePage = ({ data, intl }) => {

    constructor(props) {
        super(props)
    }

    render() {
        const photos2 = []
        this.props.data.nytovaci_matice.edges.forEach(element => {
            const publicURL = element.node.publicURL
            photos2.push(publicURL)
        })

        const photos3 = []
        this.props.data.zalisky.edges.forEach(element => {
            const publicURL = element.node.publicURL
            photos3.push(publicURL)
        })

        return (
            <React.Fragment>
                <SEO
                    title={this.props.intl.formatMessage({
                        id: 'download.title',
                    })}
                />
                <main>
                    <section className="page-section pt-5">
                        <div className="container">
                            {/* <div className="row justify-content-center section-header">
                                <div className="text-center">
                                    <h1>Reference</h1>
                                    <hr className="divider light my-4" />
                                </div>
                            </div> */}

                            <h2 className="mb-4">
                                <FormattedHTMLMessage id="reference.ref1" />
                            </h2>
                            <GalleryLightbox imgArr={photos2} />

                            <h2 className="mt-5 mb-4">
                                <FormattedHTMLMessage id="reference.ref2" />
                            </h2>
                            <GalleryLightbox imgArr={photos3} />
                        </div>
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

// ReferencePage.propTypes = {
//     intl: intlShape.isRequired,
// }

export default compose(withPageContext, withLayout, injectIntl)(ReferencePage)
